/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cloud_logic_custom": [
        {
            "name": "apiArigusService",
            "endpoint": "https://kczww3t8kb.execute-api.us-east-2.amazonaws.com/dev",
            "region": "us-east-2"
        }
    ],
    "aws_cognito_identity_pool_id": "us-east-2:a1df38f9-74e3-4dea-b8e0-e1339c2c1674",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_Y12qaT0lV",
    "aws_user_pools_web_client_id": "5gjv38ntu5qfgh8on0260kki2d",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_dynamodb_all_tables_region": "us-east-2",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "arigusLocation-dev",
            "region": "us-east-2"
        },
        {
            "tableName": "qpayBilling-dev",
            "region": "us-east-2"
        },
        {
            "tableName": "UserTrackers-dev",
            "region": "us-east-2"
        }
    ]
};


export default awsmobile;
